import {
  array,
  boolean,
  lazy,
  number,
  object,
  string,
  ValidationError
} from 'yup';
import { StepTypes, MultipleChoiceOption } from 'src/types';

const ISOSTRING_REGEX = /^(?:[-+]\d{2})?(?:\d{4}(?!\d{2}\b))(?:(-?)(?:(?:0[1-9]|1[0-2])(?:\1(?:[12]\d|0[1-9]|3[01]))?|W(?:[0-4]\d|5[0-2])(?:-?[1-7])?|(?:00[1-9]|0[1-9]\d|[12]\d{2}|3(?:[0-5]\d|6[1-6])))(?![T]$|[T][\d]+Z$)(?:[T\s](?:(?:(?:[01]\d|2[0-3])(?:(:?)[0-5]\d)?|24\:?00)(?:[.,]\d+(?!:))?)(?:\2[0-5]\d(?:[.,]\d+)?)?(?:[Z]|(?:[+-])(?:[01]\d|2[0-3])(?::?[0-5]\d)?)?)?)?$/;
export const VIDEOURL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

const uuid = string();
const trimmedString = string().trim();
const stringRequired = trimmedString.required();
// const trimmedStringAllowEmpty = trimmedString;

const dateString = string().matches(ISOSTRING_REGEX, {
  excludeEmptyString: false
});

// const group = object({
//   title: trimmedString,
//   id: uuid
// });

// const groups = array().of(group);

const steps = array().of(getStepValidations());

const zelo = object({
  id: uuid.required(),
  title: string().required(),
  description: string().nullable(),
  channelId: string().nullable(),
  channel: string().nullable(),
  steps: steps.when('mode', {
    is: (mode) => ['newsletter', 'no_link'].includes(mode),
    then: array().nullable(),
    otherwise: steps.min(1).required()
  }),
  organization: object({
    id: uuid.required(),
    name: stringRequired,
    logo: trimmedString.url().nullable()
  }),
  mode: string().required(),
  senderId: uuid.nullable()
});

const step = object({
  stepType: stringRequired.oneOf([
    'text',
    'toc',
    'video',
    'question',
    'invitation',
    'file',
    'multipleChoice',
    'consent',
    'scale',
    'feedback',
    'test'
  ]),
  isNew: boolean(),
  title: string().required()
});

const stepDesc = step.shape({
  description: string()
});

const textStep = step.shape({
  message: string().required()
});

const tocStep = stepDesc.shape({
  chapters: array()
    .of(
      object({
        title: string().required(),
        stepId: string().required(),
        stepNumber: number().required()
      })
    )
    // eslint-disable-next-line no-magic-numbers
    .min(1)
    .required()
});

const videoStep = stepDesc.shape({
  videoUrl: string().matches(VIDEOURL_REGEX).required()
});

const feedbackStep = stepDesc.shape({
  response: string()
});

const questionStep = step;

const scaleStep = stepDesc.shape({
  minLabel: string().required(),
  maxLabel: string().required(),
  response: number()
});

const OptionsTest = {
  name: 'required',
  test: (values: MultipleChoiceOption[]) =>
    values.some((value) => value.isCorrect),
  message: 'at least one correct option is required'
};

const multipleChoiceStep = stepDesc.shape({
  options: array()
    .of(
      object({
        option: string().required(),
        description: string(),
        isCorrect: boolean().required(),
        isSelected: boolean().required()
      })
    )
    // eslint-disable-next-line no-magic-numbers
    .min(2)
    .required()
});

const invitationStep = stepDesc.shape({
  fromDate: dateString.required(),
  toDate: dateString.required(),
  location: object({
    name: string().required(),
    latlng: object({
      lat: number(),
      lng: number()
    })
      .nullable()
      .notRequired()
  }).required()
});

const fileStep = stepDesc.shape({
  files: array().of(object()).required()
});

const consentStep = stepDesc.shape({
  options: array()
    .of(
      object({
        option: string().required(),
        isCorrect: boolean().required(),
        isSelected: boolean().required()
      })
    )
    .min(1)
    .required()
    .test(OptionsTest)
});

const testStep = step.shape({
  instructions: object({
    title: string().required(),
    description: string()
  }).required(),
  introduction: object({
    title: string().required(),
    description: string()
  }).required(),
  submission: object({
    title: string().required(),
    description: string()
  }).required(),
  completedMessage: object({
    passed: string(),
    failed: string()
  }),
  passPercent: number()
    .integer()
    .min(0)
    // eslint-disable-next-line no-magic-numbers
    .max(100)
    .required(),
  tryCount: number().integer().min(1),
  questions: array()
    .of(
      object({
        title: string().required(),
        description: string(),
        options: array()
          .of(
            object({
              option: string().required(),
              isCorrect: boolean().required(),
              isSelected: boolean().required()
            })
          )
          // eslint-disable-next-line no-magic-numbers
          .min(2)
          .required()
          .test(OptionsTest)
      }).required()
    )
    .required()
});

function getStepValidations() {
  return lazy<{}>((value: { stepType: StepTypes }) => {
    switch (value.stepType) {
      case StepTypes.TextStep: {
        return textStep;
      }
      case StepTypes.TocStep: {
        return tocStep;
      }
      case StepTypes.VideoStep: {
        return videoStep;
      }
      case StepTypes.QuestionStep: {
        return questionStep;
      }
      case StepTypes.InvitationStep: {
        return invitationStep;
      }
      case StepTypes.FileStep: {
        return fileStep;
      }
      case StepTypes.ConsentStep: {
        return consentStep;
      }
      case StepTypes.MultipleChoiceStep: {
        return multipleChoiceStep;
      }
      case StepTypes.ScaleStep: {
        return scaleStep;
      }
      case StepTypes.FeedbackStep: {
        return feedbackStep;
      }
      case StepTypes.TestStep: {
        return testStep;
      }
      default:
        throw new ValidationError(
          `${value.stepType} not allowed`,
          step,
          'stepType'
        );
    }
  });
}

export const ZeloValidator = zelo; // getRecipientsValidations();
